import { isEmpty } from "@/utils/functions";
import { Image as DatoImage } from "react-datocms";
import Link from "next/link";

const Logo = (props) => {
  const { regular, siteConfig } = props;
  return (
    <>
      {regular && (
        <Link href="/" className="inline-block min-w-[186px] w-full min-h-[67px] h-full">
          <DatoImage
            data={{
              src: siteConfig?.logo?.url,
              height: 67,
              width: 186,
              alt: siteConfig?.logo?.alt || "Tamaro",
              title: siteConfig?.logo?.title || "Tamaro",
              placeholder: !isEmpty(siteConfig?.logo?.blurUpThumb) ? "blur" : "empty",
              blurDataURL: siteConfig?.logo?.blurUpThumb,
            }}
          />
        </Link>
      )}
    </>
  );
};
export default Logo;
